<template>
  <div>
    <div class="title">{{ $t('information') }}</div>
    <el-form ref="form" :model="form" label-width="210px">
      <el-form-item :label="$t('Orderamount')">
        <span>
          {{
            ((Number(form.orderAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo
          }}
          {{ $t('millionDollars') }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('orderFinancingDiscount')">
        <span>{{ form.financingDiscount || 0 }}%</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmount')">
        <span>
          {{
            ((Number(form.financingAmount || 0) / $enums.UNIT_MILLION).toFixed(3) || 0) | toThousandFilterTwo
          }}
          {{ $t('millionDollars') }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('repaymentMethod')">
        <span>{{ $t('equalPrincipal') }}</span>
      </el-form-item>
      <el-form-item :label="$t('dateOfIssuance')">
        <span>{{ form.shipmentDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('deadlinefForFund')">
        <span>{{ form.deadlineFundRaising }}</span>
      </el-form-item>
      <el-form-item :label="$t('promisedPaymentDateAgain')">
        <span>{{ form.promisedPaymentDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('borrowingCycle')">
        <span>{{ form.borrowingCycle }} {{ $t('day') }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAgent')">
        <span>{{ form.financingAgent }}</span>
      </el-form-item>
      <el-form-item :label="$t('phoneNumber')">
        <span>{{ form.phoneNumber }}</span>
      </el-form-item>
      <el-form-item v-if="form.boUser" :label="$t('brandOwner')" style="width: 100%">
        <span>{{ form.boUser && form.boUser.username }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractReferenceNumber')">
        <span>{{ form.contractNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingPurpose')" style="width: 100%">
        <span>{{ form.fiancingPurpose }}</span>
      </el-form-item>
      <el-form-item :label="$t('receivableContract')" style="width: 100%">
        <el-link
            class="link-style"
            v-for="(item, index) in fileData"
            :key="index"
            :underline="false"
            type="primary"
            @click="goLink(item)"
        >{{ item.split('/')[item.split('/').length - 1] }}</el-link>
        <!-- <el-link
          v-if="form.orderFile"
          :underline="false"
          type="primary"
          :href="form.orderFile"
          @click="toReviewImg()"
          >{{
            form.orderFile.split('/')[form.orderFile.split('/').length - 1]
        }}</el-link>-->
      </el-form-item>
    </el-form>
    <div class="title">{{ $t('collectionInformation') }}</div>
    <el-form ref="form" :model="form" style="width: 1100px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" label-width="200px">
                <span v-if="form.areaId">{{ getAreaName(form.areaId) }}</span>
                <span v-else></span>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" label-width="210px">
            <div class="flex-row">
                <el-image v-if="form.receivingBankIcon" :src="form.receivingBankIcon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                <span v-if="language==='zh-cn'">{{ form.receivingBank }}</span>
                <span v-else>{{ form.receivingBankEn || form.receivingBank }}</span>
            </div>
        </el-form-item>
      <el-form-item :label="$t('swiftCode')" label-width="210px">
        <span>{{ form.swiftCode }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankNumber')" label-width="210px">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankAccount')" label-width="210px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 30px">
      <el-button
          v-if="form.status && form.status.toString() === '1'"
          class="btn-black"
          @click="markReview(true)"
          :disabled="isSubmit"
      >{{ $t('dialogMsg.approved') }}</el-button>
      <el-button
          v-if="form.status && form.status.toString() === '1'"
          class="btn-gray"
          @click="markReview(false)"
          :disabled="isSubmit"
      >{{ $t('dialogMsg.reject') }}</el-button>
      <el-button
          v-if="form.status && form.status.toString() !== '1'"
          class="btn-black"
          v-on:click="close"
      >{{ $t('btnI18n.Close') }}</el-button>
    </div>
  </div>
</template>

<script>
import {getObjByKeyValue} from "@/utils";
export default {
  name: 'mark-detail',
  data() {
    return {
      isSubmit: false,
      form: {},
      fileData: [],
        // bank update
        optionsCountry: [],
        // bank update
    };
  },
  mounted() {
      this.getAreaList();
    this.initData();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getAreaName(value) {
          if (!value) return "";
          if (!this.optionsCountry.length) return "";
          if (this.language === "zh-cn") {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).cnName;
          } else {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).enName;
          }
      },
      // bank update
    goLink(url) {
      const image = new Image();
      image.src = url;
      const imgWindow = window.open(url);
      imgWindow.document.write(image.outerHTML);
    },
    initData() {
      let _this = this;
      this.$axios
          .get('/manage-finact/order-detail', {
            params: { id: this.$route.query.id },
          })
          .then((res) => {
            if (!res.code) {
              _this.form = res.data;
              if (_this.form.orderFile.indexOf('[') === -1) {
                _this.fileData.push(_this.form.orderFile);
              } else {
                _this.fileData = JSON.parse(_this.form.orderFile);
              }
            }
          });
    },
    // 确认审核融资
    async markReview(status) {
      this.isSubmit = true;
      let _this = this;
      let params = {
        id: this.$route.query.id,
        status: status ? '2' : '5',
      };
      this.$axios
          .post('/manage-finact/review', params)
          .then((res) => {
            _this.isSubmit = false;
            if (!res.code) {
              _this.close();
            }
          });
    },
    close() {
      this.$router.push(`/mark-list`);
    },
  },
    computed: {
        language() {
            return this.$store.getters['auth/language'];
        }
    },
    watch: {
        language() {
            console.log(this.language);
        }
    }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
  margin-bottom: 0;
}
.link-style {
  display: block;
  max-width: 200px;
}
</style>
